.satellite-info {
    overflow-y: scroll;
    height: 100%;
    max-height: 100%;
    flex-grow: 1;
    font-size: 14px;
}
.main-satellite-details {
    width:100%;
}
.satellite-details-data {
    flex-grow: 1;
}
.satellite-details-action {
    padding-right:2em;
}

.satellite-details-data a {
    color:#fff;
    font-weight: bold;
    text-decoration: none;
    text-shadow: 1px 1px #0263ea;
}
.satellite-details-data.description strong {
    display: block;
    margin-top:1.6em;
}
.satellite-details-data.description br {
    padding: 0.8em;
}
.description-close-button-container {
    text-align: right;
}
.satellite-description .show-description-button, .show-description-button {
    display: inline-block;
    background: #dedede;
    padding: 0.5em;
    border-radius: 4px;
    color: #000;
    text-decoration: none;
    border: none;
    cursor: pointer;
}
.satellite-details-action .show-description-button {
    margin-top:10px;
    background-color: #888888;
    width: 100%;
    text-shadow: 1px 1px rgba(255,255,255,0.5);
}
.satellite-details-data h3, .satellite-details-data p {
    margin: 0;
    padding: 4px;
}
.satellite-details-data h4 {
    text-align: left;
    margin: 1em 0 0.2em 2em;
    padding: 0;
}
.satellite-details-data p {
    line-height: 1.4em;
    margin: 0 2em;
    text-align: left;
}
.satellite-photos {
    float: right;
    max-width: 25%;
    box-sizing: border-box;
}
.satellite-photos img {
    box-sizing: border-box;
    width: 100%;
    border: 4px solid #111;
}

.ad-support-button {
    width: 100%;
    padding: 0.5em 0;
    text-shadow: 1px 1px #000;
    cursor: pointer;
    color: rgb(203,178,12);
    text-transform: uppercase;
}
.reload-button {
    border: none;
    background: transparent;
    color: #efefef;
    text-transform: uppercase;
    margin-bottom: 1em;
    margin-top: 1em;
}

@media(max-width:768px) {
    .main-satellite-details {
        font-size: 0.75em;
    }
    .show-description-button {
        font-size:10px;
    }
}