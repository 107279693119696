.bearing-indicator {
    position: fixed;
    top: 30px;
    right: 5px;
    text-align: center;
    z-index: 3;
}
.bearing-indicator .content {
    padding: 0.5em;
    border: 1px solid #dedede;
    border-radius: 4px;
    background-color: #222;
}