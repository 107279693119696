.app-functions {
    display: flex;
    flex-direction: row;
    flex-grow:1;
    overflow-y: scroll;
}

.satellite-functions {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-left:6px;
    max-width: 60px;
    min-width: 60px;
}

.satellite-functions label {
    font-family: monospace;
    font-size: 12px;
    margin-bottom: 0.25em;
}

.satellite-function-button {
    border-radius: 7px;
    padding: 0.25em;
    font-family: monospace;
    font-size: 1.2em;
    cursor: pointer;
    border: 1px solid #aaa;
    transition: all 0.25s;
    opacity: 0.8;
    background-color: #000;
    height: 48px;
    width: 48px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    color: #efefef;
    display: block;
    margin: 0 auto 8px;
}

.satellite-function-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.satellite-function-button.active {
    opacity: 1;
    box-shadow: 0 0 2px 3px #fff;
}

.satellite-functions span label, .satellite-functions span {
    transition: all 0.25s;
}
.shrink label, .shrink input {
    transform: scale(0.8);
}

@media(max-width:768px) {
    .satellite-functions label {
        font-size: 9px;
    }
    .satellite-function-button {
        height: 24px;
        width:24px;
    }   
}