.object-status {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.7);
  color: #fff;
  padding: 0.5em 1em 1em 0.5em;
  border-radius: 0 0 8px;
}
.object-status .selected-repo {
  margin: 1em 0 0;
}