.App {
  text-align: center;
}

.earth-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 33.333%;
}

.app-controls {
  position: absolute;
  left: 0;
  right: 0;
  top: 66.666%;
  bottom: 0%;
  background: rgba(128,128,128,0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.menu.absolute-positioned {
  position: fixed;
  top:0px;
  right:0px;
  list-style: none;
  margin: 0;
  line-height: 1.3em;
  z-index:2;
}