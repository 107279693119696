.about-container {
  background: rgba(0,0,0,0.5);
  color: #fff;
  font-size: 1.2em;
  border-radius: 4px;
  text-align: left;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  overflow-y: scroll;
  padding: 2em 3em;
}
.about-stuff {
  background: rgba(0,0,0,0.8);
  padding: 1em;
}