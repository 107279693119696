@media(max-width: 768px) {
    .satellite-list {
        font-size: 10px;
    }
}

.satellite-list {
    overflow-x: scroll;
    padding: 1em;
    white-space: nowrap;
    flex-grow: 1;
}
.satellite-list.matched {
    padding-right: 100px;
}
.satellite-list.unmatched {
    color: rgb(196,196,196);
    padding: 0.8em;
}
.satellite-list-item {
    display: inline-block;
    margin: 0 1em;
    font-size: 1em;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.25s;
    border: 1px solid;
    background-image: url(/images/satellite.png);
    background-repeat: no-repeat;
    background-position: left;
    background-size: 2em;
    padding-left:2em;
}
.satellite-list-item.starlink {
    background-image: url(/images/saticons/starlink.png);
}
.satellite-list-item.moon {
    background-image: url(/images/saticons/moon.png);
}
.satellite-list-item.sun {
    background-image: url(/images/saticons/sun.png);
}
.unmatched .satellite-list-item {
    font-size: 0.75em;
}
.satellite-list-item.selected {
    background-color: rgba(158,253,191,0.6);
    box-shadow: 0 0 6px 2px #000;
}

.satellite-row {
    position: relative;
}
.satellite-text {
    display: inline-block;
    padding: 0.5em;
    text-shadow: 1px 1px #000;
    transition: all 0.25s;
}
.selected .satellite-select {
    color: #000;
    text-shadow: 1px 1px #fff;
}

.satellite-visibility {
    cursor: pointer;
    position: absolute;
    width: 48px;
    height: 48px;
    right: 0px;
    top: 0;
}
img.hider {
    position: absolute;
    transition: all 0.25s;
    top: 50%;
    margin-top: -12.5px;
    left: 0;
}
img.hider.on {
    opacity: 1;
}
img.hider.off {
    opacity: 0;;
}
.select-container {
    background-color: rgba(0,0,0,0.9);
    padding-right: 4px;
    padding-left: 4px;
    text-align: center;
}
.select-container .select-button {
    cursor: pointer;
    white-space: nowrap;
    flex-grow: 1;
}
.select-button-label span {
    font-size: 0.75em;
}
.select-button .select-button-label:after{
    content: '☰';
    display: inline-block;
    padding-right: 0.5em;
    padding-left: 0.5em;
}
.select-container img {
    margin: 0 auto;
}
.select-container .selector {
    background: rgba(0,0,0,0.8);
    color: #fff;
    font-size: 1.2em;
    border-radius: 4px;
    text-align: left;
    position: fixed;
    left: 5%;
    top: 5%;
    bottom: 5%;
    right: 5%;
    z-index: 1;
    overflow-y: scroll;
}
.selector .selector-option {
    font-size: 1.1em;
    padding: 0.5em;
    cursor: pointer;
    text-align: center;
    margin: 2px 0;
    background: rgba(128,128,128,0.25);
    transition: all 0.25s;
}
.selector .selector-option.selected {
    background: rgba(255,255,255,0.2);
}
.selector .selector-header {
    font-size: 1.2em;
    padding: 0.5em;
    text-align: center;
    margin: 2px 0;
    background: rgba(128,128,128,0.75);
}
.selector-footer button {
    width: 100%;
    font-size: 1.5em;
    font-family: monospace;
    background: rgba(255,255,255,0.95);
    border: none;
    border-radius: 2px;
    text-transform: uppercase;
}
.select-container .search {
    background: #dfdfdf;
    border: 1px solid #efefef;
    color: #000;
    font-family: monospace;
    border-radius: 2px;
    font-size: 1.2em;
}

.select-container {
    display: flex;
}
.select-container select {
    font-size: 1em;
}
