.radio {
  border: 2px solid #000;
  box-shadow: 0 0 2px 2px rgba(255,255,255,0.8);
  background-color: #000;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
  margin-left: 1em;
  float:right;
  transition: all 0.25s;
}

.radio.on {
  background-color: #efefef;
}
.radio.yellow {
  box-shadow: 0 0 2px 2px rgb(203,178,12);
}
.radio.on.yellow {
  background-color: rgb(203,178,12);
}